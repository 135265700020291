.cart-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.cart-scrollbar::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 100px;
}

.cart-scrollbar::-webkit-scrollbar-thumb {
  background-color: black;
  border: none;
  border-radius: 100px;
}

.cart-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #413839;
}

.cart-scrollbar::-webkit-scrollbar-button:single-button {
  background-color: black;
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-radius: 5px 5px 0 0;
  height: 16px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='50,00 0,50 100,50'/></svg>");
}

.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='50,00 0,50 100,50'/></svg>");
}

.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
  border-radius: 0px 0px 5px 5px;
  height: 16px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>");
}

.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>");
}

.cart-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>");
}
